
  export const style={
    inpuStyle:{
      colorInput:"#007CB3"
    },
    url:{
      urlClient:"https://mestreconstrutor.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logo1_mestreconstrutor.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: true,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Ponto/Pontos"
    },
    teamName:{
      name:"mestreconstrutor"
    },
    hasToken: false,
    hostHeader: "mestreconstrutormc"
  }
